*,
*:before,
*:after {
  box-sizing: border-box;
}

// Grouped elements
.grouped{

	display: flex;
	flex-wrap:wrap;


	> * {
		// TODO: to verify well
		// flex:1; 
	}

	&.left {
		justify-content: flex-start;
	}

	&.center{
		justify-content: center;
	}

	&.right{
		justify-content: flex-end;
	}

	&.spread{
		justify-content: space-between;
	}

	&.middle{
			align-items: center;
	}
	
	&.top {
			align-items: flex-start;
	}

	&.bottom {
			align-items: flex-end;
	}
	&.direction {

		&.column {
			flex-direction: column-reverse;
		}
		&.column-reverse {
			flex-direction: column-reverse;
		}
		&.row {
			flex-direction: row;
		}
		&.row-reverse {
			flex-direction: row-reverse;
		}
		&.row-desktop {
			@include desktop {
				flex-direction: row;
			}
		}

	}

}

// Container class
.container {

	// Mobile-first
	max-width: $container-width-mobile - 2%;
	width: $container-width-mobile;
	margin: 0px auto; // center content
	overflow: hidden;

	@include tablet {
		max-width: $container-width-tablet;
	}

	@include desktop {
		max-width: $container-width-desktop;
	}
}

// To create grids
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px 0px;
}

.row > * {
  margin: 0 10px;
  width: 100%;
  @include tablet {
  	flex: 1;
  }
}

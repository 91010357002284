.onborading-wrapper {
	margin: 3rem auto;
	@include desktop {
		width: 72rem;
	}
}

.cse-intro-banner{
	flex-direction: column;
	@include tablet {
		height: 200px;
	}
	.intro{
		background-color: $color-orange;
		padding: 0 3rem;
		padding-top: 2rem;
		color: $color-white;
		h2{
			color:  $color-white;
		}
		@include tablet {
			width:60%;
			height: 100%;
		}
	}
	.band {
		background-color: $color-white;
		height: 183px;
		width: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		@include tablet {
			width:40%;
			height: 100%;
		}
	}
}

.cse-application {
	background-color: $color-white;


	form {
		
    	margin: 2rem auto;
    	@include tablet {
    		width: 77%;
    	}
	}
}

.quiz-questions {
	margin-top: 2rem;
	label {
		margin-top: 0;
	}
}

.shift {
	margin: 1rem 0 0;
	@include tablet {
		margin-left: 1rem
	}
	.card {
		width: 100%;
	}
}

.video-controls{
	position: relative;
	@include transition-all(0.3s);
	.start-video{
		width: 50px;
		height: 50px;
		border-radius: $border-radius-all;
		
		color: $color-white;
		text-transform: uppercase;
		position: absolute;
		top: -2rem;
		font-weight: $font-weight-medium;
		padding: 0;
		border: $border-width + 1 solid $color-white;
		font-size: $font-size - 5;
		@include transition-all(0.3s);
		&.start {
			background-color: darken($color-green, 15%);
		}
		&.end {
			background-color: $color-orange;
		}
		@include tablet {
			width: 90px;
			height: 90px;
			top: -3.3rem;
			font-weight: $font-weight-black;
			font-size: $font-size;
		}
	}
}

.dialog-left {
	blockquote {
		background-color: $color-grey-30;
		// padding: 0.3rem;
	}
	.msg-box{
		width: 100%;
		@include tablet {
			width: auto;
		}
	}
	.dialog-right {
		text-align: right;
		position: relative;
		button {
			padding-right: 0;
			position: relative;
			top: 1.5rem;
			@include tablet {
				top: auto;
			}
		}
		width: 100%;
		@include tablet {
			width: auto;
		}
	}

	svg{
		display: inline-block;
		position: absolute;
	    top: -6.3rem;
	    right: 0;
	    @include tablet {
	    	top: -2rem;
	    }
	}

	.msg{
		width: 100%;
		@include transition-all(0.2s);
		h5{
			margin-left: 2rem;
			@include tablet {
				margin-left: 2rem;
			}
		}
		blockquote {
			margin-left: 1rem;
			width: 93%;

			@include tablet {
				width: auto;
			}
		}
	}

	.vi-video{
		width: 100%;
		height: 420px;
	}
}
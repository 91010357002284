// ***********************************
// FORMS
// ***********************************

input,
input[type="datetime-local"],
input[type="date"],
input[type="time"],
input[type="color"],
input[type="search"],
select,
textarea {
  // Corrects font size not being inherited in all browsers
  font-size: 100%;
  font-size: $font-size;
  font-weight: $font-weight-bold;

  // Improves appearance and consistency in all browsers
  vertical-align: baseline;

  width: 100%; // automatically take container size
  padding: $font-size;
  display: block;
  border-width: 0;
  box-shadow: none;
  background-color: $color-white;
  outline: none;

  // Force the field to stop at the container end
  box-sizing: border-box;

 // Decorate
  @include decorators();

  &:focus{
    outline: none;
    box-shadow: 0 0 3px $color-orange-30;
    @include transition-all();
  }
  
  
}

input[type="checkbox"],
input[type="radio"],
{
  width: auto;
  display: inline-block;
}


// Label
label{
  font-weight: $font-weight-bold;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: $font-size-mobile;
}

// Field icon
.field-icon{
  display: flex;
  justify-content:space-around;
  align-items: flex-start;
  background-color: $color-white;
  
  .icon{
    padding: .5rem 1.5rem;
    padding-right: 0;
  }

  input{
    &:focus{
      box-shadow: none;
    }
  }


  > * {
    align-self: center;
  }

  // Decorate
  @include decorators();
}

// Radio buttons
.radio {
  display: flex;
  justify-content: flex-start;
  align-items:center;

  .inputs{ // the real and fake inputs
    display: flex; // align new button
    & input { // hide native button
      @include opacity(0);
      width: 0;
      height: 0;

      &+.dot::before{
        content: "";
        width: 0.5em;
        height: 0.5em;
        margin-left: .22rem;
        box-shadow: inset 0.7em 0.7em $color-orange;
        border-radius: $border-radius-all;
      }
      &+.box::before{
        content: "\2713";
        margin-left: .1rem;
        color: $color-orange;
        font-weight: $font-weight-bold;
      }

      &+.dot::before,
      &+.box::before{
        transition: 180ms transform ease-in-out;
        transform: scale(0);
      }

      &:checked + .dot::before,
      &:checked + .box::before{
        transform: scale(1);
      }
    }

    // For radios
    .dot{ // The new input
      display: flex;
      align-items:center;
      margin-right: .3rem;
      width: 1em;
      height: 1em;
      border-radius: $border-radius-all;
      border: $border-width solid $color-orange;
    }

    // For checkboxes
    .box{ // The new input
      display: flex;
      align-items:center;
      margin-right: .3rem;
      width: 1em;
      height: 1em;
      border: $border-width solid $color-orange;
    }
  }

  .label{ // The label to click on

  }
}

// Helper label
.helper{
  > .text{
    font-size: $font-size - 3;
    font-style: normal;
    margin-left: $font-size;
  }

  // For fontawesome icons
  .fa, .fas, .fab, .far{
    flex: 1;
    margin-right: .5rem;
  }
}


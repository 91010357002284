body{

	font-family: $font-family-primary;
	font-size: $font-size-mobile;
	font-weight:$font-weight-medium;

	color: $color-grey-10;
	background-color: darken($color-grey-40, 5%);
	line-height: $line-height;

	// antialiasing mode font rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// Avoid horizontal scroll of the page
	overflow-x: hidden;

	// Responsive font size
	@include desktop {
		font-size: $font-size;
	}
}


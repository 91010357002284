.hide{
	@include opacity(0);
	visibility: none;
	width: 0;
	height: 0;
	transform: translate(0, -50px);
}

.show {
    @include opacity(1);
    visibility: visible;
    @include transition();
}

.lock{
	overflow: hidden;
}
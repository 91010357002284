// ***********************************
// MIXINS
// ***********************************

@mixin transition-all($time:1s){
	-webkit-transition: all $time ease-in-out;
	-moz-transition: all $time ease-in-out;
	-ms-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;
}

@mixin fade($time:0.5s){
	-webkit-transition: background-color $time ease-in-out;
	-moz-transition: background-color $time ease-in-out;
	-o-transition: background-color $time ease-in-out;
	-ms-transition: background-color $time ease-in-out;
	transition: background-color $time ease-in-out;
}



@mixin transition($transition...) {
  -webkit-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

@mixin translate() {
  transition: transform 100ms ease 200ms, opacity 100ms ease 0s;
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}


@mixin shadow {
	@include transition(all .2s ease-in-out);
	-webkit-box-shadow: 1px 1px 5px rgba(black,.2); // iOS <4.3 & Android <4.1
	box-shadow: 1px 1px 5px rgba(black,.2);
	// box-shadow: rgb(0 0 0 / 27%) 7.5px 15px 15px;
}

@mixin arrow-down {
	bottom: -5px;
	height: 0;
	border-top: 5px solid $primary-color;
	border-left: 5px solid rgba(0,0,0,0);
	border-right: 5px solid rgba(0,0,0,0);
	content: "";
	left: 50%;
	margin-left: -5px;
	position: absolute;
	width: 0;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: #{alpha(opacity=$opacity-ie)};
}

// ***********************************
// LAYOUT MIXINS
// ***********************************

// Layput media queries
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin tablet {
	@media (min-width: #{$tablet}) {
		@content;
	}
}

@mixin hd {
	@media (min-width: #{$hd}) and ( max-width: 1280px) {
		@content;
	}
}



// PANEL
@mixin panel($theme: white) {
	display: flex;
	justify-content: space-between;
	align-items: center;
  background: $theme;
  box-shadow: 0 0 1px rgba($theme, .25);
  margin: 1rem 0;
  padding: .2rem 1rem;
  font-size: $font-size - 1;
  font-weight: $font-weight-bold;
}

@mixin decorators {
	&.bordered{
		border-width: $border-width;
		border-style: solid;
	}

	&.bordered-bottom{
		border-bottom-width: $border-width;
		border-bottom-style: solid;
	}

	&.bordered-top{
		border-top-width: $border-width;
		border-top-style: solid;
	}

	&.rounded {
		border-radius: $border-radius;
	}
	&.rounded-tops {
		border-radius: $border-radius $border-radius 0 0;
	}

	&.default{
		color: $color-grey-10;
		border-color: $color-grey-30;
		& > * {
			color: $color-grey-10;
			border-color: $color-grey-30;
		}
	}

	&.primary{
		color: $color-orange-10;
		border-color: $color-orange-40;
		& > * {
			color: $color-orange-10;
			border-color: $color-orange-40;
		}
	}

	&.secondary{
		color: darken($color-green, 20%);
		border-color: $color-green-30;
		& > * {
			color: darken($color-green, 20%);
			border-color: $color-green-30;
		}
	}

	&.fill-plain {
		background-color: $color-white;
	}

	&.fill-default {
		background-color: $color-grey-30;
	}

	&.fill-primary {
		background-color: $color-orange-40;
	}

	&.fill-secondary {
		background-color: $color-green-40;
	}

	&.disabled{
		border-color: $color-grey-20;
		color: $color-grey-20;
		font-weight: $font-weight-bold;
	}
}
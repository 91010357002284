// ***********************************
// TYPOGRAPHY
// ***********************************

// paragraphs
p{
	margin: 1rem 0;
	direction: ltr;
}


// Headings
h1,
h2,
h3,
h4,
h5,
h6{
	color: $color-grey;
	margin-top: 10px; 
	margin-bottom: .7rem; 
	clear: both;
	letter-spacing: -.5px;
	text-align: left;
	font-weight: $font-weight-black;
}

h1{
	font-size: $h1;
}
h2{
	font-size: $h2;
}
h3{
	font-size: $h3;
}
h4{
	font-size: $h4;
}
h5{
	font-size: $h5;
}
h6{
	font-size: $h6;
}

// bold text
b, strong{
	font-weight: $font-weight-black;
}



// Text Tone
.text-tone-light{
	color: $color-grey-20;
}

.text-tone-dark{
	color: $color-grey;
}

.text-tone-primary{
	color: $color-orange;
}

.text-tone-secondary{
	color: $color-green;
}

// Text helpers
.lowercase{
	text-transform: lowercase;
}

.uppercase{
	text-transform: uppercase;
}

.capitalize{
	text-transform: capitalize;
}

.text {
	&.center {
		text-align: center;
	}
	&.right{
		text-align: right;
	}

	// sizes
	.small{
		font-size: $font-size - 3;
	}
	.big{
		font-size: $font-size + 3;
	}
}

// Write a mixin for it
.margin{
	&.top-one{
		margin-top: 1rem;
	}
	&.top-two{
		margin-top: 2rem;
	}
}
//**********************
//  BUTTONS   
//**********************

.button, button{
	cursor: pointer;
	border: 0;
	padding: 1rem 1.5rem;
	background-color: transparent;
	font-weight: $font-weight-black;
	font-family: $font-family-primary;
	text-align: center;

	// To review based on context
	display: inline-block;
	// margin: .5rem;

	// Decorate
  	@include decorators();

}

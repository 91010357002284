// ***********************************
// IMAGES
// ***********************************

img, .image {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
	object-fit: cover;

	&.rounded{
		border-radius: $border-radius;
	}

	&.circle{
		border-radius: 50%;
	}
}

// Figure caption
figure{

    margin: 0;
	figcaption{
		text-align: center;
		font-size: $font-size;
		font-style: italic;
		letter-spacing: -.5px;
	}
}


/* Make sure embeds and iframes fit their containers */
embed, iframe, object {
	max-width: 100%;
}
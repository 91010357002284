// ***********************************
// ICONS
// ***********************************
// import font awesome
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css');


// ***********************************
// FONT AWESOME
// http://fortawesome.github.io/Font-Awesome/icons/
// ***********************************

.fa-facebook{
	font-size: 25px;
}

.fa-google-plus{
	font-size: 25px;
}

.fa-twitter{
	font-size: 25px;
}

.fa-youtube{
	font-size: 25px;
}

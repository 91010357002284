.cpanel-wrapper{

}

.cpanel-home-header{
	background-color: $color-white;
	// margin-top: 2rem;

	.header-nav{
		width: 100%;
		padding: 1rem;
		padding-left: 2rem;
		@include tablet {
			width: 290px;
		}
	}

	.topbar{
		display: flex;
		flex:1;
		justify-content: space-between;
		align-items: center;
		label{
			margin: 0
		}

		margin: 1rem;

		@include tablet {
			margin: 2rem;
		}

	}
}

.cpanel-body{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;

}

.cpanel-content{
	background-color: transparent;
	flex: 1;
	margin: 1rem 0;

	@include tablet {
		margin: 2rem;
	}

	.search-bar{
		margin-bottom: 1rem;
		input{
			background-color: $color-grey-30;
		}
	}
}

.cpanel-cards-group{

	@include tablet {
		display: flex;
		flex-wrap:wrap;
		justify-content: center;
		align-items: center;
	}

	.card{
		margin: 0;
		margin-bottom: 1rem;
		flex:1;
		@include tablet {
			// margin-bottom: 0;
		}
	}

	.loggedin{
		width: 100%;
		@include tablet {
			// width: 30%;
			margin-right: 1rem;
		}
		&.last {
			margin-right: 0;
		}
	}

	.new-registrations {
		width: 100%;
		@include desktop {
			flex: 2;
		}
	}
}
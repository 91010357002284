// ***********************************
// VARIABLES
// ***********************************

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Asap&family=Roboto:wght@300;400&display=swap');

// TYPE VARIBLES
$font-family-primary: 'Roboto', sans-serif;;
$font-family-title: 'Asap', sans-serif;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-bold: 500;
$font-weight-black: bold;

$font-size: 14px;
$font-size-mobile: 16px;

$line-height: 1.625;
$line-height-mobile: 1.625;

$h1: 2.5em;
$h2: 2em;
$h3: 1.5em;
$h4: 1em;
$h5: .8em;
$h6: 0.5em;

//LAYOUT Breakpoint
$desktop: 1050px;
// $desktop: 1024px; //includes Ipad Pro in desktops
$tablet: 768px;
$hd: 1051px;

// Container dimensions
$container-width-desktop: 95%;
$container-width-tablet: 85%;
$container-width-mobile: 90%;


// COLORS
// Primary colors
$color-orange: #F26222;
$color-green: #11E981;
$color-blue: #0487FF;
$color-grey: #212429;

// Shades of primaries
// The bigger the value the lighter
$color-orange-10: #f37238;
$color-orange-20: #f5814e;
$color-orange-30: #f69164;
$color-orange-40: #f7a17a;

$color-green-10: #29eb8e;
$color-green-20: #41ed9a;
$color-green-30: #58f0a7;
$color-green-40: #70f2b3;

$color-blue-10: #1d93ff;
$color-blue-20: #369fff;
$color-blue-30: #4fabff;
$color-blue-40: #68b7ff;

$color-grey-10: #495057;
$color-grey-20: #ACB5BD;
$color-grey-30: #DDE2E5;
$color-grey-40: #F8F9FA;


// Utils
$color-white: #ffffff;
$color-links: $color-green-10;


// Other
$border-width: 1px;
$border-radius: 10px;
$border-radius-button: 8px;
$border-radius-all: 50%;

$z-level-1:1;
$z-level-2:2;
$z-level-3:3;

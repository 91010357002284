//**********************
//  MODALS   
//**********************
.modal{
	//  use main element for backdrop
	background-color: rgba(darken($color-grey, 100%), 0.8);
	z-index: $z-level-3;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;

	// content wrapper
	.slot{
		width: 700px;
		max-width: 90%;
		margin: 1.75rem auto;
		position: relative;
		z-index: $z-level-3;
		// Some decorators
		@include decorators();
		box-shadow: 0px 28px 31px darken($color-grey, 90%);

		@include tablet {
			max-width: 100%;
		}

		.content {
			display: flex;
			flex-direction:column;
			width: 100%;
			pointer-events:auto;
			outline: 0;
		}

		// Modal header
	    header, .header{
			position: relative;
			padding: 0 1rem;
			
			h2{
				font-size: $h2 - 0.5;
				font-weight: $font-weight-black;
			}
			.fa {
				padding-right: 0.5rem;
			} 
			.close{
		        cursor: pointer;
		        padding: 0;
		        margin: 0;
			}

			// Some decorators
			@include decorators();
		}

		// Modal Body
		.body{
			.salade {
				padding: 3rem 2rem;
				@include tablet {
					padding: 3rem 4rem;
				}
			}
		}

		// Modal footer
		footer {
			&.spaced {
				padding: 1rem;
			}
			// Some decorators
			@include decorators();
		}
	}
	

	&.show {
        @include opacity(1);
        visibility: visible;
        @include transition();
        .slot{
        	transition: transform .3s ease-out;
			transform: translate(0, 50px);
        }
    }

    

}
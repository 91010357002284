.global_notice{
	position: fixed;
	top: 5px;
	right: 5px;
	background-color: $color-orange-40;
	color: $color-grey;
	padding: .5rem;
	border-radius: $border-radius-button;
	width: 80%;
	z-index: $z-level-3;
	@include transition-all();
	@include tablet {
		width: 40%;
	}
}
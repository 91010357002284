// ***********************************
// CARDS
// ***********************************

.card{
	display: inline-block;
	margin: 1rem;
	background-color: $color-white;
	vertical-align: top;
	overflow: hidden;

	figure, 
	.figure{
		max-width: 100%;
	}

	.body{
		flex:1 1 auto;
		padding: 1rem;
	}


	&.bordered{
		border-width: $border-width;
		border-style: solid;
	}

	&.rounded {
		border-radius: $border-radius-button;
	}

	&.primary{
		color: $color-orange;
		border-color: $color-orange;
	}

	&.secondary{
		color: darken($color-green, 20%);
		border-color: $color-green;
	}

	&.fill-primary {
		background-color: $color-orange-40;
		color: $color-orange;
	}

	&.fill-secondary {
		background-color: $color-green-40;
		color: darken($color-green, 20%);
	}

	&.disabled{
		border-color: $color-grey-20;
		color: $color-grey-20;
		font-weight: $font-weight-bold;
	}

	&.pill{
		border-radius: $border-radius-button + 10;
		display: inline-block;
	}

}
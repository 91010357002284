
// MAIN SITE NAVIGATION
.site-main-nav{
	background-color: lighten($color-orange-40, 25%);
	padding: 1rem 0;
	position: relative;
	@include tablet {
		padding: 2rem 0;
	}
	

	.logo{
		img{
			width: 101px;
		}
	}

	&.fixed {
		background-color: $color-white;
		position:fixed;
		left: 0;
		right: 0;
		width: 100%;
		@include transition-all(.2s);
		z-index: $z-level-2;
	}

	.site-home-get-started{
		a{
			padding: .2rem .7rem;
		}
		.icon{
			padding: 0;
		}
	}
	
}

// Small menu
.desktop{
	// display: none;
	display: block;
	position: absolute;
	background-color: $color-white;
	transform: translateX(-105%);

	max-width: 100%;
	// left: 1rem;
	// right: 1rem;

	&.show{
		transform: translateX(0%);
		@include transition-all(0.3s);
	}
	@include desktop {
		transform: translateX(0%);
		@include transition-all(0.3s);
		background-color: transparent;
		position: relative;
		max-width: auto;
		left: auto;
		right: auto;
	}

	.grouped {
		display: block;
		padding: 1.3rem;

		@include desktop{
			display: flex;
			padding: 0;
		}
	}

	@include desktop {
		display: block;
		a{
			padding: 0.5rem 1.3rem;
		}
	}
}
.main-small{
	top: 91px;
	a{
		padding: .3rem 0;
		color: $color-grey-10;
		@include desktop {
			display: block;
			padding: .5rem 1rem;
			&:hover {
				color: $color-orange;
			}
		}
	}
	@include desktop {
		top: 0;
	}
}

.secondary-small{
	top: 292px;
	@include desktop {
		top: 0;
	}
}


// CPANEL MAIN NAVIGATION
.cpanel_navigation{
	background-color: $color-white;
	width: 100%;
	padding: 1rem 0;
	position: absolute;
	transform: translateX(-105%);

	max-width: 100%;
	// left: 1rem;
	// right: 1rem;

	&.show{
		transform: translateX(0%);
		@include transition-all(0.3s);
		position: relative;
	}

	@include tablet {
		width: 300px;
	}

	@include desktop {
		transform: translateX(0%);
		@include transition-all(0.3s);
		position: relative;
		max-width: auto;
		// left: auto;
		// right: auto;
	}
	
	li{
		padding: .7rem 0;
		position: relative;
		&:hover, &.active {
			color: $color-grey;
			 @include transition-all(0.2s);
			 >a{
			 	border-right: 3px solid $color-orange;
			 }
			i.fa{
				color: $color-orange;
			}

			ul{
				opacity: 1;
				width: 300px;
				height: auto;
				display: block;
			}
		}
		> ul {
			opacity: 0;
			width: 0;
			height: 0;
			display: none;
			position: absolute;
			right: 0px;
			top: -100%;
			border-left: 3px solid $color-orange;
			background: $color-white;
			box-shadow: 10px 0px 9px #ededed;
			@include transition-all();
			z-index: $z-level-3;
			@include tablet {
				right: -300px;
			}
			li{
				padding: 0;
			}
			>a{
				padding: .4rem .5rem;
				border-right: 3px solid transparent;
			}
		}
	}
	a{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: .5rem;
		font-size: $font-size + 4;
		color: $color-grey-10;
		border-right: 3px solid transparent;
		font-weight: $font-weight-black;
		@include transition-all(0.2s);
		&:hover{
			color: $color-grey;
			border-right: 2px solid $color-orange;
			 @include transition-all(0.2s);
			i.fa{
				color: $color-orange;
			}
		}
	}
	i.fa {
		padding: .3rem;
		padding-right: 1rem;
		padding-left: 1rem;
		display: block;
		font-size: $font-size + 4;
	}

}


.burger{
	border: $border-width solid $color-orange-10;
	border-radius: $border-radius-button;
	padding: 0 .7rem;
	padding-top: .3rem;
	.fa{
		font-size: $font-size + 5;
		color: $color-grey;
	}
	@include desktop {
		display: none;
	}
}
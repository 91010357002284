.cpanel-users-list{
	width: 100%;
	margin: 0;

	.filters{
		width: 100%;
		label{
			margin: 0;
		}
		@include tablet {
			width: 20%;
		}
	}
}

// Add new user button
.last{
	&:hover{
		border-color: $color-green;
	}
}

.users{
	.user{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: $border-width solid $color-grey-30;
		padding: .5rem 0;
		font-size: $font-size - 3;
		& > *{
			flex: 1;
		  	text-align:left;
		  	&:first-child, &:last-child{
		  		flex:0;
		  	}
		  	&:nth-child(3){
		  		flex: 2;
		  		padding-left: 1rem;
		  	}
		  	&:nth-child(2){
		  		flex: 0;
		  	}
		}
		.panel, label{
			margin:0;
		}
		strong{
			display: block;
		}
		img{
			max-width: 45px;
		}
	}
}

.list-pagination{
	margin-top: 1rem;
	& > * {
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
	}
}

.cp_add_user_type{
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
	margin-top: 2rem;
	
	li{
		padding: 0;
		width: 100%;
		margin-bottom: 1rem;
		@include tablet {
			width: 44%;
			margin: 1rem;
		}
	}

	button{
		border: $border-width+1 solid $color-orange-10;
		border-radius: $border-radius-button;
		@include transition-all(0.2s);
		width: 100%;
		padding: 2rem;
		&:hover{
			border-color: transparent;
			background-color: $color-orange;
			color: $color-white;
		}
	}
}
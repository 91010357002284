.spinner{
	z-index: $z-level-3;
	background-color: $color-grey;
	@include opacity(.6);
	position: absolute;
	display: flex;
    align-items: center;
    justify-content: center;
	width: 100%;
	height: 100%;
	right: 0;
	top: 0;
	.spin{
		@include opacity(1);
		display: block;
		width: 47px;
		height: 47px;
		border: 6px dashed $color-white;
		border-radius: $border-radius-button + 20;
		margin: .3rem;
		animation: spin 1s infinite linear;
		vertical-align: baseline;
	}
}

@keyframes spin {
	from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
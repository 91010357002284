// circular progress
// https://www.dottedsquirrel.com/circular-progress-css/

// <div class="circular">
//   <div class="inner"></div>
//   <div class="number">100%</div>
//   <div class="circle">
//     <div class="bar left">
//       <div class="progress"></div>
//     </div>
//     <div class="bar right">
//       <div class="progress"></div>
//     </div>
//   </div>
// </div>

// const numb = document.querySelector(".number");
// let counter = 0;
// setInterval(() => {
//   if(counter == 100 ){
//     clearInterval();
//   }else{
//     counter+=1;
//     numb.textContent = counter + "%";
//   }
// }, 80);


.circular{
  height:100px;
  width: 100px;
  position: relative;
  transform:scale(2);
}
.circular .inner{
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -40px 0 0 -40px;
  background: #dde6f0;
  border-radius: 100%;
 
}
.circular .number{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index:10;
  font-size:18px;
  font-weight:500;
  color:#4158d0;
}
.circular .bar{
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  -webkit-border-radius: 100%;
  clip: rect(0px, 100px, 100px, 50px);
}
.circle .bar .progress{
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
  background: #4158d0;
}
.circle .left .progress{
  z-index:1;
  animation: left 4s linear both;
}
@keyframes left{
  100%{
    transform: rotate(180deg);
  }
}
.circle .right {
  transform: rotate(180deg);
  z-index:3;
 
}
.circle .right .progress{
  animation: right 4s linear both;
  animation-delay: 4s;
}
@keyframes right{
  100%{
    transform: rotate(180deg);
  }
}


// SVG element
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.chartpie {
  
}
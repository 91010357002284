// ***********************************
// LINKS
// ***********************************

a{
	text-decoration: none;
	
	// Decorators
	@include decorators();
	
}


a, a:visited {
	@include transition-all(.5s);
}
a:hover, a:active, a:focus {
	color: $color-orange;
	@include transition-all(.5s);
}

// ADJUST THE HOMEPAGE

// MAKE SURE TO ABSTRACT THE CUSTOM CLASSES 
// TO AVOID COLISIONS WITH OTHER STYLES

.site-logo{
	display: block;
	width: 100%;
	text-align: center;
	@include desktop {
		width: auto;
	}
}

.home-arrow-back{
	margin-top: 2rem;
	@include desktop {
		margin-top: 0;
	}
}

.home-panel-security{
	// padding: 0;
	flex-wrap:wrap;
	.icon{
		width: 100%;
		display: block;
		margin-top: 2rem;
		text-align: center;
	}
	.message{
		text-align: center;
	}
}


// OTP PINS

.customerVerifyForm{
}

.otp-pins {
	@include desktop{
		width: 80%;
		margin: 0 auto;
	}
	flex-wrap: nowrap;
	input{
		margin: 0;
		padding: 15px 5px;
		text-align: center;
		font-weight:bold;
		font-size: $font-size + 7;
		@include desktop{
			margin: 5px;
		}
	}
}

.home-title{
	font-size: 1.5rem;
	@include tablet{
		font-size: $h2;
	}
}

.home-instructions{
	line-height: 1!important;
	margin-bottom: 1rem!important;
}


// MAIN SITE HOME PAGE
.site-hero {
	background-color: lighten($color-orange-40, 25%);
	padding: 7rem 0;

	.home-stimulus{
		text-align: center;
		// padding-top: 3rem;
		h3{
			text-align: center;
			@include tablet {
				text-align: left;
			}
		}
		h1{
			margin-top: 0;
			padding-top: 0;
			line-height: $line-height - .4;
			color: darken($color-green, 5%);
			margin-bottom: 2rem;
			text-align: center;
			@include tablet {
				text-align: left;
				font-size: $h1 + 2;
				line-height: $line-height - .5;
			}
		}

		@include tablet {
			text-align: left;
		}

		.button{
			&:hover {
				background-color: darken($color-green, 15%);
			}
			width: 100%;
			@include tablet {
				width: auto;
			}
		}
	}
	.home-illustration{
		display: none;
		@include desktop {
			display: block;
		}
	}
}

// Offer previews
.home-most-requested {
	padding: 7rem 0;
	

	.home-cards{
		display: flex;
		justify-content: space-between;
		flex-wrap:wrap;

		@include desktop {
			justify-content: start;
		}

		.card{
			background-color: $color-white;
			width: 100%;
			margin: 0;
			margin-bottom: 2rem;
			@include tablet {
				width: calc(48% - 15px);
				margin-bottom: 15px;
			}
			@include desktop {
				width: calc(24% - 15px);
				margin: 10px;
			}
		}
	}
}

// Home services
.home-services{
	background-color: $color-green-40;
	padding: 7rem 0;

	.list-services{
		aside {
			@include tablet {
				width: 30%;
			}
		}
		.services{
			display: flex;
			justify-content: space-between;
			flex-wrap:wrap;

			li{
				background-color: $color-white;
				padding: 1.2rem;
				position: relative;
				margin-bottom: 2rem;

				h4{
					color: $color-orange-10;
				}

				.fa{
					position: absolute;
					right: 0;
					top:0;
					display: block;
					padding: .3rem;
					background-color: $color-orange;
					color: $color-white;
					font-size: $font-size + 7;
					align-self: center;
				}

				@include tablet {
					width: 100%;

				}
				@include desktop {
					width: 30%;
					margin: 10px;
				}
			}
			@include tablet {
				width: 65%;
				justify-content: flex-end;
				max-height: 300px;
				overflow: auto;
			}
			@include desktop {
				max-height: 100%;
				overflow: visible;
			}
		}
	}
}

// Home faq

.home-faq{
	padding: 5rem 0;

	.faq-questions{
		display:flex;
	}
	.illustration{
		width: 100%;
		@include tablet {
			width: 40%;
		}
		@include desktop {
			width: 45%;
		}
	}
	.questions{
		@include tablet {
			width: 60%;
			max-height: 300px;
			overflow: auto;
		}
		@include desktop {
			width: 50%;
			max-height: 100%;
			overflow: visible;
		}
	}

	.question{
		position: relative;
		h3{
			&::before{
				content: "";
				width: 17px;
				height: 17px;
				left: -30px;
				top: 13px;
				background-color: $color-white;
				border: 2px solid $color-orange;
				border-right: 0;
				border-radius: $border-radius-all;
				position: absolute;
			}
		}
		p{
			margin-left: 1rem;
			padding-left: 1rem;
			border-left: $border-width + 1 dashed $color-green-30;
		}
	}
}

.howitworks {
	padding: 8rem 0;
	position: relative;
	.section-title {
		text-align: center;
		margin-bottom: 60px;
		font-size: 28px;
		text-transform: uppercase;
		font-weight: 900;
		color: $color-white;
		.title {
			color: $color-grey-10;
			font-size: 20px;
			text-transform: inherit;
		}
	}

	.service {
		text-align: left;
		box-shadow: 0px 0px 106px 0px rgba(0, 0, 0, 0.14);
		padding: 45px;
		border-radius: 6px;
		background: #fff;
		overflow: hidden;
		position: relative;
		margin-bottom: 30px;
		@include desktop {
			width: calc(32% - 15px);
			margin: 15px;
		}
		i{
			font-size: 3rem;
			min-width: 3rem;
		}
		&:hover::after{
			transform: translateY(0);
		}
		&::after{
			height: 5px;
			width: 100%;
			content: '';
			background: linear-gradient(90deg, $color-orange-20 0%,$color-orange-40 80%);
			position: absolute;
			left: 0;
			bottom: 0;
			transition: all ease 0.3s;
			transform: translateY(5px);
		}
	}

	.ligne {
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}

.tag {
	display: inline-block;
  	width: auto;
	height: 38px;
	background-color: $color-orange-40;
	-webkit-border-radius: 3px 4px 4px 3px;
	-moz-border-radius: 3px 4px 4px 3px;
	border-radius: 3px 4px 4px 3px;
	
	border-left: 1px solid $color-orange-40;

	/* This makes room for the triangle */
	margin-left: 19px;
	
	position: relative;
	
	color: white;
	font-weight: 300;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 22px;
	line-height: 38px;

	padding: 0 10px 0 10px;
	/* Makes the triangle */
	&:before {
		content: "";
		position: absolute;
		display: block;
		left: -19px;
		width: 0;
		height: 0;
		border-top: 19px solid transparent;
		border-bottom: 19px solid transparent;
		border-right: 19px solid $color-orange-40;
	}
	/* Makes the circle */
	&:after {
		content: "";
		background-color: white;
		border-radius: 50%;
		width: 4px;
		height: 4px;
		display: block;
		position: absolute;
		left: -9px;
		top: 17px;
	}
}


.bend-bg {
    background: $color-orange-40;
    clip-path: polygon(0 9%, 100% 0, 100% 92%, 0% 100%);
    padding: 200px 0;
}

.signin {
	text-align: center;
	border: 2px solid $color-orange-40;
	padding: 2rem;
	border-radius: 27px 0 27px 0;
	background-color: $color-white;
	@include tablet{
		padding: 4rem;
		width: 70%;
		margin: 1rem auto;
	}
	.content {
		// display: flex;
		// justify-content: center;
		// align-items: stretch;
	}
	label {
		display: block;
		margin-bottom: 1rem;
		font-size: 1.2rem;
	}
	input{
		padding: 1rem!important;
		border: 1px solid $color-grey-20;
	}

}

.footer {
	padding: 5rem 0;
	ul,li{
		margin:0; padding:0;
		list-style-type: none;
	}
	.container, ul {
		display: flex;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	.container {
		flex-wrap: wrap-reverse;
	}
	p{
		color: $color-grey;
	}
	.profile{
		li{
			&:first-child{
				padding-right: 1rem;
			}
		}
		h3{
			text-align: left;
			color: $color-grey-20;
			margin: 0;
			padding: 0;
			span {
				display: block;
				font-weight: bold;
				padding-top: .3rem;
				font-size: 33px;
			}
		}
		p{
			margin-bottom: 0;
			padding-bottom: 0;
		}

		ul{
			display: flex;
			flex-wrap: nowrap;
		}
	}

	.links {
		a {
			display: block;
			padding: .5rem;
			color: $color-grey-10;
			@include tablet {
				padding: .5rem 1rem;
			}
			&:hover{
				color: $color-orange-40
			}
		}
	}
}